import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'gstrTwoADownload',
  components: {
    ModelSelect
  },
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    currentPage: function() {
      this.getCompanyComplianceList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCompanyComplianceList();
    }
  },
  data() {
    return {
      loading: false,
      showValueSetModal: false,
      setTimeVsetCode: null,
      vsetCode: null,
      parent_value_set_id: null,
      perPage: 50,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedFinancialYear: {
        value: null,
        text: null
      },
      selectedReturnType: {
        value: null,
        text: null
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      downloadPayload: null,
      rowDetails: null,
      ReturnTypeList: [
        {
          value: 'GSTR1',
          text: 'GSTR1'
        },
        {
          value: 'GSTR3B',
          text: 'GSTR3B'
        }
      ],
      flags: {
        apr: false,
        may: false,
        jun: false,
        jul: false,
        aug: false,
        sep: false,
        oct: false,
        nov: false,
        decem: false,
        jan: false,
        feb: false,
        march: false,
      },
      companyComplianceData: [],
      companyComplianceFields: [
        {
          key: 'gst_number',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'apr',
          class: 'col-fix text-center'
        },
        {
          key: 'may',
          class: 'col-fix text-center'
        },
        {
          key: 'jun',
          class: 'col-fix text-center'
        },
        {
          key: 'jul',
          class: 'col-fix text-center'
        },
        {
          key: 'aug',
          class: 'col-fix text-center'
        },
        {
          key: 'sep',
          class: 'col-fix text-center'
        },
        {
          key: 'oct',
          class: 'col-fix text-center'
        },
        {
          key: 'nov',
          class: 'col-fix text-center'
        },
        {
          key: 'decem',
          label: 'Dec',
          class: 'col-fix text-center'
        },
        {
          key: 'jan',
          class: 'col-fix text-center'
        },
        {
          key: 'feb',
          class: 'col-fix text-center'
        },
        {
          key: 'march',
          class: 'col-fix text-center'
        }
      ],
      flag: false
    };
  },
  validations: {
    selectedLegalEntity: {
      value: {
        required
      }
    },
    selectedFinancialYear: {
      value: {
        required
      }
    },
    selectedReturnType: {
      value: {
        required
      }
    }
  },
  mounted() {
  },
  methods: {
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = {...this.downloadPayload};
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'complainceDashboard/companyComplianceData',
        'company-compliance',
      () => (this.loader = false)
      );
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.selectedGSTNumber = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.CUSTOM_FY) {
        this.selectedFinancialYear = {
          value: null,
          text: null
        };
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOM_FY) {
        this.selectedFinancialYear = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },
    clear() {
      this.selectedLegalEntity = this.defaultValue;
      this.selectedReturnType = this.defaultValue;
      this.selectedGSTNumber = this.defaultValue;
      this.selectedFinancialYear = this.defaultValue;
      this.totalRows = null;
    },
    rowSelected(items) {
      this.rowDetails = items;
    },
    getCompanyComplianceList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectedLegalEntity.value,
        rtn_type: this.selectedReturnType.value,
        custom_fy: this.selectedFinancialYear.value,
        gst_num: this.selectedGSTNumber.value
      };
      this.downloadPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/companyComplianceData', payload)
        .then(response => {
          if (response.status === 200) {
            this.companyComplianceData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            if(this.selectedReturnType.text === 'GSTR3B') {
              this.flags = {
                apr: false,
                may: false,
                jun: false,
                jul: false,
                aug: false,
                sep: false,
                oct: false,
                nov: false,
                decem: false,
                jan: false,
                feb: false,
                march: false,
              }
            for (let i of this.companyComplianceData) {
              for (let key in i) {
                const date = Number(i[key].substring(0, 2));
                if (key === 'apr' && date > 20) {
                  this.flags.apr = true;
                } else if(key === 'may' && date > 20) {
                  this.flags.may = true;
                } else if(key === 'jun' && date > 20) {
                  this.flags.jun = true;
                } else if(key === 'jul' && date > 20) {
                  this.flags.jul = true;
                } else if(key === 'aug' && date > 20) {
                  this.flags.aug = true;
                } else if(key === 'sep' && date > 20) {
                  this.flags.sep = true;
                } else if(key === 'oct' && date > 20) {
                  this.flags.oct = true;
                } else if(key === 'nov' && date > 20) {
                  this.flags.nov = true;
                } else if(key === 'decem' && date > 20) {
                  this.flags.decem = true;
                } else if(key === 'jan' && date > 20) {
                  this.flags.jan = true;
                } else if(key === 'feb' && date > 20) {
                  this.flags.feb = true;
                } else if(key === 'march' && date > 20) {
                  this.flags.march = true;
                }
              }
            }
          } else if(this.selectedReturnType.text === 'GSTR1') {
            this.flags = {
              apr: false,
              may: false,
              jun: false,
              jul: false,
              aug: false,
              sep: false,
              oct: false,
              nov: false,
              decem: false,
              jan: false,
              feb: false,
              march: false,
            }
            for (let i of this.companyComplianceData) {
              for (let key in i) {
                const date = Number(i[key].substring(0, 2));
                if (key === 'apr' && date > 20) {
                  this.flags.apr = true;
                } else if(key === 'may' && date > 11) {
                  this.flags.may = true;
                } else if(key === 'jun' && date > 11) {
                  this.flags.jun = true;
                } else if(key === 'jul' && date > 11) {
                  this.flags.jul = true;
                } else if(key === 'aug' && date > 11) {
                  this.flags.aug = true;
                } else if(key === 'sep' && date > 11) {
                  this.flags.sep = true;
                } else if(key === 'oct' && date > 11) {
                  this.flags.oct = true;
                } else if(key === 'nov' && date > 11) {
                  this.flags.nov = true;
                } else if(key === 'decem' && date > 11) {
                  this.flags.decem = true;
                } else if(key === 'jan' && date > 11) {
                  this.flags.jan = true;
                } else if(key === 'feb' && date > 11) {
                  this.flags.feb = true;
                } else if(key === 'march' && date > 11) {
                  this.flags.march = true;
                }
              }
            }
          }
        }        
        this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
